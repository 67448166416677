// the semi-colon before function invocation is a safety net against concatenated 
// scripts and/or other plugins which may not be closed properly.
;(function ($, window, document, undefined ) {

    // Create the defaults once
    var pluginName = 'nowPlaying',
        defaults = {
        	width: 300,
        	height: 400,
        	songs: 30,
        	theme: "dark",
        	daysback: 14
        };
        
    /* Models and Collections */
	var Song = Backbone.Model.extend({
	    defaults: {
	    	"timestamp": null,
	    	"songuuid": null,
	    	"songartist": null,
	    	"songtitle": null,
	    	"songartwork": null,
	    	"songitunespreview": null,
	    	"songituneslink": null,
	    	"up": 0,
	    	"down": 0	    	
	    }
	});
	
	var Ad = Backbone.Model.extend({
	    defaults: {
	    	"streamuuid": null,
	    	"aduuid": null,
	    	"image": null,
	    	"text": null,
	    	"title": null,
	    	"type": null,
	    	"url": null    	
	    }
	});
	
	var ModalState = Backbone.Model.extend({
	    defaults: {
	    	"view": "",
	    	"date": null,
	    	"songId": null	    	
	    }
	});
	
	var SongCollection = Backbone.Collection.extend({
	    model: Song
	});
	
	var SearchCollection = Backbone.Collection.extend({
	    model: Song
	});
	
	var AdCollection = Backbone.Collection.extend({
	    model: Ad
	});
	
	/* Views */
	
	// Widget
	var WidgetView = Backbone.View.extend({
	    tagName: "div",
	    className: "bedigital bedigital-nowplaying",
	    
	    initialize: function() {
	    	var self = this;
	    	this.state = this.options.state;
	    	
	      	this.options.songs.bind('add', function(song){
				self.addSong(song);
			});
			
			this.options.ads.bind('add', function(ad){
				self.addAd(ad);
			});
			
	      	this.render();
	    },
	    
	    events: {
    		"click .footer a":	"showModal",
    		"click .header":	"showModal"
    	},
	    
	    addSong: function(song){
	    	// update the header
	    	var thisartwork = song.attributes.songartwork;
	    	if (!song.attributes.songartwork) {
                thisartwork = 'https://c805990.r90.cf2.rackcdn.com/noart-75.png';
	    	}
	    	$(this.el).find(".header").find(".artwork").find("img").attr("src", thisartwork);
	    	$(this.el).find(".header").find(".song-artist").html(song.attributes.songartist);
	    	$(this.el).find(".header").find(".song-title").html(song.attributes.songtitle);
	    	
	    	//show the previous row, pop this new song onto the stack, but hide it
	    	var songEl = new SongListItemView({ model:song, modal:false, state:this.state });
	    	$(this.el).find("ul li:first").slideDown("slow");
	    	$(this.el).find("ul").prepend(songEl.el);
	    	$(this.el).find("ul li:first").hide();
	    },
	    
	    addAd: function(ad){
	    	var adEl = new AdListItemView({ model:ad, modal:false });
	    	$(adEl.el).insertAfter($(this.el).find("li").filter(":first"));
	    	$(adEl.el).slideDown("slow");
	    	
	    	// Post the ad impression
	    	BEDIGITAL.ADVIEW(adEl.model.get("aduuid"), adEl.model.get("streamuuid"));
	    },
	    
	    showModal: function(e){
	    	e.preventDefault();
	    	this.state.set({view:"today", songId:null});
	    	$("#bedigital-nowplaying-modal").jqmShow();
	    },
	    
	    render: function(){
	    	$(this.el).html($.tmpl("nowplaying-holder")).appendTo($(this.options.element));
	    	$(".bedigital-nowplaying").addClass("bedigital-nowplaying-"+this.options.widgetOptions.theme);
	    	// Size the widget
	    	$(this.el).css({
	    		"width": this.options.widgetOptions.width,
	    		"height": this.options.widgetOptions.height
	    	});
	    	$(this.el).find("ul").css({
	    		"height": this.options.widgetOptions.height - 105
	    	});
	    	return this;
	    }
	});
	
	// Modal Window
	var ModalView = Backbone.View.extend({
	    tagName:  "div",
	    id: "bedigital-nowplaying-modal",
	    className: "bedigital bedigital-modal jqmWindow",
	    
	    initialize: function() {
	    	var self = this;
	    	this.widgetOptions = this.options.widgetOptions;
	    	this.state = this.options.state;
	    	this.app = this.options.app;
	    	
	      	this.options.songs.bind('add', function(song){
				self.addSong(song);
			});
			
			this.options.ads.bind('add', function(ad){
				self.addAd(ad);
			});
			
			this.options.searchResults.bind('add', function(song){
				self.addSearchResult(song);
			});
			
			this.options.searchResults.bind('reset', function(){
				$(self.el).find("ul.search").empty();
			});
			
			this.state.bind('change', function(state){
				self.changeViewState(state);
			});
			
	      	this.render();
	      	this.fillDateSelect(this.options.widgetOptions.daysback);
	    },
	    
	    events: {
    		"click a.close": "closeModal",
    		"change .date-select": "changeDate"
    	},
    	
    	closeModal: function(e){
    	   e.preventDefault();
    	   $(this.el).jqmHide();
    	},
    	
    	changeDate: function(e){
            var self = this;
            var playDate = $(e.target).val();
            if(playDate === "0"){
                this.state.set({view:"today"});
            }else{
                this.state.set({view:"search",date:playDate});
            }
    	},
    	
    	showLoading: function(msg){
            var body = $(this.el).find(".body");
            var loadingMsg = msg || "Loading playlist…";
            body.find("ul").hide();
            body.find("p.loading").html(loadingMsg).show();
    	},
    	
    	hideLoading: function(){
    	   $(this.el).find(".body").find(".loading").hide();
    	},
    	
    	getPlaylist: function(playDate, cb){
            var tz = moment().zone();
            $.getJSON(this.app.apiUrl+'/'+this.app.apiKey+'/nowplaying/songs?date='+playDate+'&tz='+tz+'&callback=?', function(data) {
                cb(data);
		    });
    	},
    	
	    addSong: function(song){
	    	var songEl = new SongListItemView({model:song, modal:true, state:this.state});
	    	$(this.el).find("ul").prepend(songEl.el);
	    },
	    
	    addSearchResult: function(song){
	    	var songEl = new SongListItemView({model:song, modal:true});
	    	$(this.el).find("ul.search").append(songEl.el);
	    },
	    
	    addAd: function(ad){
	    	var adEl = new AdListItemView({ model:ad, modal:true });
	    	$(adEl.el).insertAfter($(this.el).find("li").filter(":first"));
	    	$(adEl.el).slideDown("slow");
	    },
	    
	    expandSong: function(song){
	        $(this.el).find("li").find(".expanded").hide();
	        $(this.el).find("li").find(".collapsed").show();
	        song.trigger("click");
	    },
	    
	    fillDateSelect: function(daysBack){
            var today = new Date();
            for (i=0; i<=daysBack; i++){
                var thisDate = new Date();
                thisDate.setDate(today.getDate() - i);
                var thisMonth = thisDate.getMonth() + 1;
                var option = $("<option>")
                    .val(thisDate.getFullYear()+"-"+thisMonth+"-"+thisDate.getDate())
                    .val(moment(thisDate).format("YYYY-MM-DD"))
                    .html(moment(thisDate).format("dddd, MMM D, YYYY"))
                    .appendTo($(this.el).find(".date-select"));
            }
	    },
	    
	    changeViewState: function(state){
	        var self = this;
	        if(state.get("view") == "today"){
                $(this.el).find(".date-select").val("0");
                $(this.el).find(".header-title").html("Recently Played");
                $(this.el).find("ul.today").show();
                $(this.el).find("ul.search").hide();
                
                if(state.get("songId") == null){
                    var firstSong = $(this.el).find("ul.today").find("li:first");
                    $(this.el).find(".body").scrollTop(0);
                    this.expandSong(firstSong);
                }else{
                    var selectedSong = $(this.el).find("#"+state.get("songId"));
                    this.expandSong(selectedSong);
                    $(this.el).find(".body").scrollTop($(this.el).find(".body").scrollTop() + selectedSong.position().top);
                }
	    	}else if(state.get("view") == "search"){
                $(this.el).find(".header-title").html("Songs played on:");
                $(this.el).find("ul.today").hide();
                $(this.el).find("ul.search").empty();
                //$(this.el).find("ul.search").show();
                self.showLoading();
                this.getPlaylist(self.state.get("date"), function(data){
                    if(data.songs.length === 0){
                        $(self.el).find("p.loading").html("Sorry, No songs were found for the selected date").show();
                    }else{
                        self.options.searchResults.reset();
                        $.each(data.songs, function(key,val){
                            self.options.searchResults.add(val);
                        });
                        self.hideLoading();
                        $(self.el).find("ul.search").show();
                    } 
                });
	    	}
	    },
	    
	    render: function(){
	    	var self = this;
	    	var winheight = $(window).height();
	    	var modalHeight = $(window).height() - 200;
	    	var topOffset = modalHeight / 2
	    	
	    	$(this.el).html($.tmpl("modal-window-detail")).appendTo("body");
	    	$(this.el).addClass("bedigital-nowplaying-modal-"+this.widgetOptions.theme);
	    	$("#"+this.id).jqm({
                onHide: function(){
                    $("#"+self.id).hide();
                    $(".jqmOverlay").remove();
                    self.state.set({view:""});    
                }
	    	});
	    	
	    	$(this.el).css({
	    		"height": modalHeight,
	    		"margin-top": "-"+topOffset.toFixed()+"px"
	    	});
	    	
	    	$(this.el).find(".body").css({
	    		"height": modalHeight - 60
	    	});
	    	
	    	$(this.el).find("ul.today").show();
	    	$(this.el).find("ul.search").hide();

	    	return this;
	    }
	});
	
	var SongListItemView = Backbone.View.extend({
	    tagName:  "li",
	
	    initialize: function() {
	    	_.bindAll(this, 'remove');
	      	this.model.bind("remove", this.el.remove);
	      	this.modal = this.options.modal;
	      	this.state = this.options.state;
	      	this.render();
	    },
	    
	    events: {
    		"click a.media":	"previewClick",
    		"click":			"songClick",
    		"mouseover":		"songMouseOver",
    		"mouseout":			"songMouseOut"
    	},
    	
    	songClick: function(e){
    		if( $(this.el).hasClass("modal") && $(this.el).find(".expanded").css("display") != "block" ){
    			$(this.el).parents(".bedigital-modal").find("ul > li").find(".expanded").hide();
    			$(this.el).parents(".bedigital-modal").find("ul > li").find(".collapsed").show();
    			$(this.el).find(".expanded").fadeIn("slow");
    			$(this.el).find(".collapsed").hide();
    		}else if($(this.el).hasClass("widget")){
                $("#bedigital-nowplaying-modal").jqmShow();
                this.state.set({songId:$(e.target).parents(".widget").attr("id"),view:"today"});
    		}
    	},
    	
    	songMouseOver: function(e){
    		if($(this.el).hasClass("widget")){
                $(this.el).addClass("over");
    		}
    	},
    	
    	songMouseOut: function(e){
    		if($(this.el).hasClass("widget")){
                $(this.el).removeClass("over");
    		}
    	},
    	
    	previewClick: function(e){
            e.stopPropagation();
    		e.preventDefault();
    		var previewUrl = this.model.get("songitunespreview");
    		
    		if($(this.el).find("a.now-playing-stop").length){
    			$("#bedigital-media-player").jPlayer("stop");
				$(".bedigital").find("a.media").removeClass("now-playing-stop");
                $(".bedigital").find("a.media").addClass("now-playing-play");
    		}else{
    			$(".bedigital").find("a.media").removeClass("now-playing-play");
                $(".bedigital").find("a.media").addClass("now-playing-stop");
    			$("#bedigital-media-player").jPlayer("setMedia", {m4a:previewUrl});
    			$("#bedigital-media-player").jPlayer("play");
    		}
    	},
	    
	    render: function(){
	    	// Show the local time in the view
	    	var localDate = new Date(Math.floor(this.model.get("timestamp") * 1000));
	    	var template = (this.modal)? "song-list-item-modal" : "song-list-item";
	    	
	    	if(this.modal){
	    	  $(this.el).addClass("modal");
	    	}else{
	    	  $(this.el).addClass("widget");
	    	}

	    	$(this.el).html($.tmpl(template, this.model.toJSON()));
	    	$(this.el).find(".timestamp").html(moment(localDate).format("h:mmA"));
            $(this.el).find(".expanded-timestamp").find("span").html(moment(localDate).format("h:mmA"));
	    	
	    	$(this.el).attr("id",this.model.cid);
	    	return this;
	    }
	});
	
	var AdListItemView = Backbone.View.extend({
	    tagName:  "li",
	    className: "ad",
	
	    initialize: function() {
	    	_.bindAll(this, 'remove');
	      	this.modal = this.options.modal;
	      	this.render();
	    },
	    
	    events: {
    		"mouseover": "adMouseover",
    		"mouseout": "adMouseout",
    		"click":	 "adClick"
    	},
    	
    	adMouseover: function(e){
    	   $(this.el).find(".title").addClass("adHover");
    	},
    	
    	adMouseout: function(e){
    	   $(this.el).find(".title").removeClass("adHover");
    	},
    	
    	adClick: function(e){
            var self = this;
            // Post the ad impression
	        BEDIGITAL.ADCLICK(this.model.get("aduuid"), this.model.get("streamuuid"), function(result){
	            window.location.href = self.model.get("url");
	        });
    	},
	    
	    render: function(){
	    	var template = (this.modal)? "modal-ad" : "widget-ad";

	    	$(this.el).html($.tmpl(template, this.model.toJSON()));
	    	return this;
	    }
	});

    // The actual plugin constructor
    function Plugin( element, options ) {
        this.options = $.extend( {}, defaults, options) ;
        this._defaults = defaults;
        this._name = pluginName;
        this.element = element;
        this.core = window.BEDIGITAL;
        this.apiKey = window.BEDIGITAL_APIKEY;
        this.apiUrl = window.BEDIGITAL.API_URL;
        this.sessionKey = this.core.SESSION_KEY;
        this.data = null;

        this.init();
    }

    Plugin.prototype.init = function () {
    	var self = this;
    	this.createTemplates();
    	this.songs = new SongCollection();
    	this.ads = new AdCollection();
    	this.state = new ModalState();
    	this.searchResults = new SearchCollection();
    	this.widget = new WidgetView({
    		element: this.element,
    		songs: this.songs,
    		ads: this.ads,
    		state: this.state,
    		widgetOptions: this.options
    	});
    	this.modal = new ModalView({
    		songs: this.songs,
    		ads: this.ads,
    		state: this.state,
    		app: this,
    		searchResults: this.searchResults,
    		widgetOptions: this.options
    	});
        
        // Include widget CSS
        // Can't do this the jQuery way because of an IE bug
        var headID = window.document.getElementsByTagName("head")[0];         
        var cssNode = window.document.createElement('link');
        cssNode.type = 'text/css';
		cssNode.rel = 'stylesheet';
		cssNode.href = 'https://0a10e977061973754d96-7906491bec9c811008e63fa5f4ab9fac.ssl.cf2.rackcdn.com/nowplaying.min.css';
        cssNode.media = 'screen';
        headID.appendChild(cssNode);
        
        // Load intial playlist data
        var tz = moment().zone();
        $.getJSON(this.apiUrl+'/'+this.apiKey+'/nowplaying/songs?since=now&count='+this.options.songs+'&tz='+tz+'&callback=?', function(data) {
			var reversedSongs = data.songs.reverse();
			$.each(reversedSongs, function(k,v){
				self.songs.add(v);
			});
		});
		
		// Bind to new playlist events from core
		$(BEDIGITAL).bind("addStream.bedigital", function(e, stream){
		    switch(stream.streamtypeid){
		    	case 1: // This is an ad
		    		// render new Ad
		    		var adObject = _.extend(stream.detail, {streamuuid: stream.uuid});
		    		self.ads.add(adObject);
		    		break;
		    	case 2: // New playlist song
                    var songObject = _.extend(stream.detail, {timestamp: stream.timestamp} );
		    		self.songs.add(stream.detail);
		    		break;
		    }
		});
        
    };
    
    Plugin.prototype.createTemplates = function() {
    	// TODO: pass in the theme, dark or light
    	$.template("nowplaying-holder", '\
		    <div class="header">\
		    	<div class="artwork">\
					<img src="" width="75" height="75">\
				</div>\
				<div class="details">\
					<div class="song-title"></div>\
					<div class="song-artist"></div>\
					<div class="equalizer">\
    				    <img src="https://c805990.r90.cf2.rackcdn.com/equalizer.gif" width="25" height="15"/>\
    				</div>\
					<a class="buy-itunes" target="_blank" href="">Buy on iTunes</a>\
				</div>\
		    </div>\
		    <ul></ul>\
		    <div class="footer">\
		    	<a href="#" class="more">More songs we\'ve played &raquo;</a>\
		    </div>'
		);
		
		$.template("song-list-item", '\
			<div class="timestamp">${timestamp}</div>\
			<div class="title" title="${songartist} - ${songtitle}">\
				<span class="artist">${songartist}</span> "${songtitle}"\
			</div>'
		);
		
		$.template("song-list-item-modal", '\
			<div class="collapsed">\
				<div class="timestamp">${timestamp}</div>\
				<div class="title" title="${songartist} - ${songtitle}">\
					<div class="song-title">\
						${songtitle}\
					</div>\
					<div class="song-artist">\
						${songartist}\
					</div>\
				</div>\
			</div>\
			<div class="expanded">\
				<div class="artwork">\
					{{if songartwork.length}}<img src="${songartwork}" width="100" height="100">{{/if}}\
				</div>\
				<div class="details">\
					<div class="song-title">\
						${songtitle}\
					</div>\
					<div class="song-artist">\
						${songartist}\
					</div>\
					{{if songituneslink.length}}<a class="buy-itunes-modal" target="_blank" href="${songituneslink}">Buy on iTunes</a>{{/if}}\
				</div>\
				{{if songituneslink.length}}\
				<div class="preview-holder">\
					<a class="media now-playing-play" href="#"></a>\
					<div class="label">Preview</div>\
				</div>\
				{{/if}}\
				<div class="expanded-timestamp">\
					Played at <span>${timestamp}</span>\
				</div>\
			</div>'
		);
		
		$.template("widget-ad", '\
			<div class="image"><img src="${image}" width="20" height="20"></div>\
			<div class="ad-notice">AD</div>\
			<div class="title">\
				${title}\
			</div>\
		');
		
		$.template("modal-window-detail", '\
			<div class="header">\
				<a href="#" class="close"></a>\
				<select class="date-select">\
				    <option value="0">Today</option>\
				</select>\
				<span class="header-title">Recently Played</span>\
			</div>\
			<div class="body">\
                <ul class="search"></ul>\
				<ul class="today"></ul>\
				<p class="loading"></p>\
			</div>\
		');
		
		$.template("modal-ad", '\
            <div class="image"><img src="${image}" width="50" height="50"></div>\
            <div class="ad-notice">AD</div>\
            <div class="title">\
                <div class="ad-title">\
                	${title}\
                </div>\
                <div class="ad-description">\
                	${text}\
                </div>\
            </div>\
		');
    }
    
    // A really lightweight plugin wrapper around the constructor, 
    // preventing against multiple instantiations
    $.fn[pluginName] = function ( options ) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin( this, options ));
            }
        });
    }

})( jQuery, window );
